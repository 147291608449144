.wrapper3 {
  width: 100%;
  height: 100vh;
  min-height: 500px;
  background-color: #000000;
  color: white;
  font-family: "Zen Dots";
  display: flex;
  flex-direction: column;
}
.cover {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  /* background-color: rgba(rgb(0, 0, 0, 0.8)); */
  background-color: black;
  opacity: 0.9;
}

.covertext {
  color: white;
  font-size: 80px;
  z-index: 99;
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.container {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
}

.contentcontainer {
  display: flex;
  flex-direction: column;

  height: 20%;
  justify-content: space-between;
}

.title {
  font-size: 40px;
  margin-top: 40px;
  margin-left: 40px;
  margin-right: 40px;
}

.button {
  background-color: white;
  color: black;
  width: 400px;
  height: 70px;
  font-size: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.text {
  font-size: 20px;
}

@media screen and (max-width: 768px) {
  .container {
    flex-direction: column;
    align-items: center;
  }
  .contentcontainer {
    align-items: center;
  }
  .covertext {
    font-size: 40px;
  }
}
