.wrapper2 {
  background-color: rgb(255, 255, 255);
  height: 50vh;
  max-height: 1300px;
  min-height: 500px;
  font-family: "Zen Dots";
  color: rgb(0, 0, 0);
  padding-top: 80px;
  padding-bottom: 80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-left: 80px;
  padding-right: 80px;
}

.textcontainer2 {
  display: flex;
  font-size: 40px;
  text-align: center;
}

.statscontainer {
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.stat {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 20px;
  text-align: center;

  /* make a animation that rolls in the new number from the top when the number changes */
}

@media screen and (max-width: 850px) {
  .wrapper2 {
    padding-left: 20px;
    padding-right: 20px;
  }
  .statscontainer {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    margin-top: 50px;
  }
  .stat {
    grid-column: span 2;
  }

  .stat:nth-last-child(1):nth-child(odd) {
    grid-column: 2 / span 2;
  }
  .textcontainer2 {
    font-size: 30px;
  }
}
