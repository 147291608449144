body {
  background-color: rgb(255, 255, 255);
  margin: 0;
  padding: 0;
  font-family: "Zen Dots";
}

.wrapperMain {
  display: flex;

  flex-direction: row;
  justify-content: center;
  gap: 100px;
  align-items: center;
  background-color: rgb(255, 255, 255);
  height: 100vh;
  max-height: 1500px;
  min-height: 700px;
  padding-left: 40px;
  padding-right: 40px;
}

.textContainer {
  display: flex;
  max-width: 700px;
  font-size: 35px;
  font-family: "Zen Dots";
}

.animationContainer {
  display: flex;
  padding: 20px;
  width: 40vw;
  height: 30vw;
}
.navbar {
  font-family: "Zen Dots";
  font-size: 15px;
  text-decoration: none;
}
.headeritem {
  text-decoration: none;
  color: black;
  margin-right: 40px;
}

@media screen and (max-width: 768px) {
  .wrapperMain {
    flex-direction: column;
    justify-content: center;
  }
  .textContainer {
    margin-top: 200px;
    text-align: center;
    font-size: 20px;
  }
  .animationContainer {
    width: 100%;
    height: 50%;
  }
}
