.wrapper {
  background-color: rgb(0, 0, 0);
  max-height: 1300px;
  height: 70vh;
  min-height: 500px;
  font-family: "Zen Dots";
  color: white;
  padding-top: 80px;
  padding-bottom: 80px;
  width: 100%;
}

.textcontainer {
  display: flex;
  margin-left: 40px;
  margin-right: 40px;
  font-size: 40px;
}
.abz {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
}

/* Slider container styles */
/* Slider container styles */
/* Slider container styles */

/* Slider item styles */
.slider-item {
  width: 502px;
  height: auto;
  margin-right: 100px;
}

@media screen and (max-width: 768px) {
  .slider-item {
    width: 300px;
  }
  .textcontainer {
    margin-left: 0px;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
}
